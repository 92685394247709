import request from '@/api/http.js'
function banner (id) {
  return request({
    url: `/v1/banner/${id}`
  })
}
function info (id) {
  return request({
    url: `/v1/web/info/${id}`
  })
}

export {
  banner,
  info

}
