<template>
  <div class="home">
    <Navigations :routerValue="routerValue" :webInfo="Info" />
    <div class="banner">
      <el-carousel :interval="5000" arrow="always" v-if="bannerList.length > 0">
        <el-carousel-item  v-for="item in bannerList" :key="item.id">
          <img :src="item.img" style="width:100%; height: 850px;" alt="">
        </el-carousel-item>
      </el-carousel>
      <el-carousel v-else>
        <el-carousel-item>
          <img src="../assets/img/newBanner.png" alt="" style="width: 100%;height: 850px;">
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="banner_box">
        <div class="title">
          <span>合</span>
          <span>规</span>
          <span>高</span>
          <span>效</span>
          <span>低</span>
          <span>价</span>
        </div>
        <div class="company">医药互联网推广商</div>
        <div class="medicine">让医药人阳光执业</div>
      </div> -->
    </div>
    <div class="introduce">
      <div class="electronic">
        <div class="electronic_box">
          <div class="left_box">
            <div>ABOUT</div>
            <div>米墨电商</div>
            <div></div>
            <div>
              &nbsp; &nbsp;&nbsp; &nbsp;米墨电商运营团队创建于2018年,现拥有运营团队近80人,是全国医药行业最具有影响力的全网营销运营服务商之一 ;主营业务包括B2C渠道管理、内容营销、电商营销等业务。<br>
              &nbsp; &nbsp;&nbsp; &nbsp;能够帮助药企解决的网络乱价问题,净网周期一般为三到四个月;通过全网布网以及营销策划实现营销增量。从内容优化和电商销量两个最基础需求出发,多平台共创战路,拓宽营销的深度与广度,为客户提供覆盖营销各个环节的全方位服务。
            </div>
          </div>
          <div class="right_box">
            <img src="../assets/img/mimoCommerce.png" alt="">
          </div>
        </div>
      </div>
      <div class="option">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>解决方案</div>
          <div></div>
        </div>
        <div class="option_box">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4330.svg" alt="">
                  <div>企业产品推广营销筹备</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4376.svg" alt="">
                  <div>扎实平台自营升级用户运营</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4377.svg" alt="">
                  <div>产品全网净网以及后续长期净网维护</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <div style="height: 60px;"></div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4378.svg" alt="">
                  <div>拓展站外推广增量流量运营</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4379.svg" alt="">
                  <div>百度软文、小红书等主流媒体线上推广</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4380.svg" alt="">
                  <div>优化渠道 转化存量</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="product">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>业务介绍</div>
          <div></div>
        </div>
        <div class="matrix">
          <div class="inner">
            <img src="../assets/img/buddy.jpg" alt="">
            <div class="text">
              <div>线上产品规划</div>
              <div>选定企业头部产品<br>主打线上营销</div>
            </div>
          </div>
          <div class="inner">
            <img src="../assets/img/businesses.jpg" alt="">
            <div class="text">
              <div>电商市场管理</div>
              <div>帮助药企完善销售控制管理<br>制度、修订线下销售合同布<br>网上架规范展开营销渠道帮<br>助企业建立渠道管控规则</div>
            </div>
          </div>
          <div class="inner">
            <img src="../assets/img/compliance.jpg" alt="">
            <div class="text">
              <div>布网上架</div>
              <div>规范展开营销渠道<br>帮助企业建立渠道管控规则</div>
            </div>
          </div>
          <div class="inner">
            <img src="../assets/img/cooperation.jpg" alt="">
            <div class="text">
              <div>品牌矩阵推广</div>
              <div>线上线下联动推广、帮助企业<br>完成互联网化形象设计</div>
            </div>
          </div>
        </div>
        <!-- <div class="matrix_mock">
          <div>借助医药电商趋势与营销团队升级为契机</div>
          <div>以精准市场授权与合规管理为基础</div>
          <div>全场景品牌推广与利益融合为抓手</div>
          <div>深度触达消费者与提升品牌认知为目的</div>
          <div>实现领先行业的品牌新生态</div>
        </div> -->
      </div>
      <div class="compete">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>核心竞争力</div>
          <div></div>
        </div>
        <div class="description">一体化助力客户实现品牌产品的保值（线上维价）、增值（数字市场）与营销上量
          <el-divider></el-divider>
        </div>
        <div class="description_box">
          <div class="inner_box">
            <img src="../assets/img/deal.jpg" alt="">
            <div>情报技术</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/personnel.jpg" alt="">
            <div>品牌授权</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/glasses.jpg" alt="">
            <div>数字创意</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/mark.jpg" alt="">
            <div>预付保证金</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/performance.jpg" alt="">
            <div>业绩对赌</div>
          </div>
        </div>
      </div>
      <div class="cooperation">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>合作药企</div>
          <div></div>
        </div>
        <div class="partner">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper1.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper2.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper3.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper4.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper5.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper6.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper7.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper8.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper9.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom :webInfo="Info"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Navigations from '@/components/Navigation.vue'
import bottom from '@/components/bottom.vue'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import { info, banner } from '@/api/home'
Swiper.use([Autoplay, Navigation, Pagination])
export default {
  name: 'Home',
  components: {
    Navigations,
    // eslint-disable-next-line vue/no-unused-components
    bottom
  },
  data () {
    return {
      index: 0,
      routerValue: 'serviceDetails',
      Info: {},
      bannerList: [],
      id: 3 // 米墨电商id为3
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef, no-unused-vars
    var swiper = new Swiper('.swiper-container', {
      speed: 4000,
      slidesPerView: 5,
      spaceBetween: 30,
      loop: true,
      loopedSlides: 4,
      loopAdditionalSlides: 2,
      allowTouchMove: false,
      autoplay: {
        delay: 1,
        // stopOnLastSlide: false,
        disableOnInteraction: false
      }
    })
  },
  created () {
    info(this.id).then(res => {
      if (res.code === 200) {
        this.Info = res.data
      }
    })
    banner(this.id).then(res => {
      if (res.code === 200) {
        this.bannerList = res.data
      }
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__container {
  height: 850px;
}

.home {
  width: 100%;
  // background-color: #f5f5f5;
  .banner {
    width: 100%;
    height: 850px;

    .banner_box {
      position: absolute;
      left: 50%;
      top: 30%;

      transform: translateX(-50%);
      z-index: 2;
      text-align: center;
      width: 50%;
      color: #ffffff;

      .title {
        font-size: 70px;
        font-weight: 600;
        line-height: 97px;
        font-family: 'gfzt';
        letter-spacing: 10px;

        :nth-child(2n) {
          color: #91D200;
        }

        :nth-child(2n-1) {
          margin-left: 50px;
        }

      }

      .company {
        margin-top: 350px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        line-height: 45px;
      }
    }
  }

  .introduce {
    width: 1500px;
    margin: 0 auto;

    .electronic {
      width: 100%;
      height: 300px;
      padding: 100px 0;
      background: url('../assets/img/about_bg.png') no-repeat bottom;

      .electronic_box {
        width: 95%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .left_box {
          width: 50%;
          padding: 0 2.5%;

          :nth-child(1),
          :nth-child(2) {
            color: #333333;
            font-weight: 600;
            font-size: 25px;
            text-align: left;
          }

          :nth-child(3) {
            margin-top: 10px;
            width: 40px;
            height: 4px;
            background: #13A3F0;
          }

          :last-child {
            margin-top: 10px;
            text-align: left;
            line-height: 45px;
            font-size: 14px;
          }
        }

        .right_box {
          width: 640px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .option {
      width: 100%;
      height: 500px;
      margin-top: 50px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .option_box {
        width: 1400px;
        height: 400px;
        padding: 50px 60px;

        .plates {
          display: flex;
          align-items: center;

          :first-child {
            width: 105px;
            height: 105px;
            margin-left: 20px;
          }

          :last-child {
            margin-left: 30px;
            font-size: 20px;
            color: #333333;
            text-align: left;
          }
        }
      }
    }

    .business {
      width: 100%;
      // height: 600px;
      margin-top: 50px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .mickActive {
        display: none !important;
      }

      .mock {
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        height: 113px;

        .item {
          width: 300px;
          height: 50px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          .box {
            margin-left: 20px;
            width: 100%;
            text-align: left;
            font-size: 12px;
            color: #7E7E7E;

            .title {
              font-size: 16px;
              color: #000;
              margin-bottom: 10px;
            }

            .describe {
              line-height: 20px;
              display: flex;
              align-items: center;

              :first-child {
                color: #13A3F0;
              }
            }
          }
        }
      }

      .business_box {
        width: 1400px;
        height: 500px;
        padding: 25px 60px;
        display: flex;

        .business_box_left {
          width: 40%;
          height: 500px;
          padding: 0px 10px;
          background: url('../assets/img/u3460.png') no-repeat bottom;
          background-size: 600px 450px;

          // display: flex;
          // flex-wrap: wrap;
          .list {
            width: 60%;
            height: 20%;
            overflow: hidden;
          }

          .recommend {
            margin: 30px 0;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;

            // height: 90;
            :first-child {
              width: 40px;
              height: 40px;
              margin-right: 20px;
            }

            :last-child {
              font-weight: 600;
              cursor: pointer;
            }
          }
        }

        .business_box_right {
          height: 500px;
          width: 60%;
          background-image: url('../assets/img/yiliao.jpg');
          background-size: cover;
          background-position: center;
          border-radius: 5px;
          opacity: 0.5;
        }
      }
    }

    .product {
      width: 100%;
      height: 700px;
      margin-top: 50px;
      position: relative;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }
      .matrix {
        height: 600px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1400px;
        padding: 50px 60px 0 60px;
        .inner {
          position: relative;
          overflow: hidden;
          height: 250px;
          width: 650px;
          transition: 1.5s;
          box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
          &:hover {
            img{
              transition: 1s;
              transform:scale(1.1);
            }
          }
        }
        img {
          transition: 1s;
          width: 350px;
          height: 250px;
          float: right;
        }

        .text {
          transition: 1s;
          width: 580px;
          position: absolute;
          top: 40px;
          left: 30px;
          text-align: left;
          padding: 0 20px;

          :first-child {
            font-size: 30px;
            font-weight: 600;
            color: #000;
          }

          :last-child {
            margin-top: 10px;
            font-size: 21px;
            letter-spacing: 1px;
            color: #555555;
          }
        }
      }

      .matrix_mock {
        pointer-events: none;
        display: none;
        transition: 1s;
        position: absolute;
        top: 90px;
        left: 0;
        width: 1400px;
        height: 405px;
        margin-left: 60px;
        background-color: rgb(0, 0, 0, .7);
        color: #ffffff;
        font-size: 30px;
        padding: 100px 0;
        line-height: 80px;
      }
    }

    .compete {
      overflow: hidden;
      width: 100%;
      height: 600px;
      // margin-top: 50px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .description {
        margin-top: 15px;
        color: #7E7E7E;
        text-align: left;
        margin-left: 60px;
      }

      .description_box {
        padding-left: 60px;
        height: 400px;
        width: 1440px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .inner_box {
          width: 18%;
          height: 370px;
          box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5);

          img {
            width: 259px;
            height: 280px;
            border-radius: 5px;
          }

          :last-child {
            margin-top: 25px;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
          }
        }
      }
    }

    .cooperation {
      width: 1500px;
      height: 300px;
      // padding: 30px 60px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .partner {
        margin-top: 80px;
        width: 100%;
        overflow: hidden;
      }

      .swiper-wrapper {
        /* 这里是改变其动画效果为匀速形式，不加此样式的话会造成滚动卡顿，看起来效果不平滑 */
        /* 样式做了各种浏览器的兼容 */
        transition-timing-function: linear !important;
        -webkit-transition-timing-function: linear !important;
        -moz-transition-timing-function: linear !important;
        -ms-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
      }

      .swiper-slide {
        width: 170px;
        height: 86px;
        // background: #FFFFFF;
        border: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
