<template>
  <div class="navs">
    <div class="top">
      <!-- <div class="top" @scroll="handleScroll(e)"> -->
      <div class="content">
        <div class="logo" >
          <!-- <img src="../assets/img/aaa.png" alt=""> -->
          <img src="@/assets/img/logo.png" alt="" v-if="!logo">
          <img :src="logo" alt="" v-else>
        </div>
        <div class="right">
          <nav class="main-nav">
            <div class="a" :class="{ activeFont: routerValue == 'serviceDetails' }" @click="changeNav()" ><span data-hover="服务详情" >服务详情</span></div>
            <!-- <div class="a" :class="{ activeFont: routerValue == 'contactUs' }"  @click="changeNav('contactUs')"><span data-hover="加入我们"  >加入我们</span></div> -->
            <!-- <a class="a"  href="https://mimo.90c.la/liangfang/front/login" target="_blank"><span data-hover="用户登录"  >用户登录</span></a> -->
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { WOW } from 'wowjs'
export default {
  props: ['routerValue', 'webInfo'],
  name: 'navigation',
  data () {
    return {
      logo: ''
    }
  },
  watch: {
    'webInfo.logo': function (n, o) {
      this.logo = n
      this.updata()
    }
  },
  methods: {
    changeNav (routerValue) {
      if (routerValue) {
        this.$router.push('/' + routerValue)
      } else {
        this.$router.push('/Home')
      }
    },
    updata () {
      console.log(this.logo, '变了')// 1
    }
  }
}
</script>

<style scoped lang="scss">
.navs {
  width: 100%;
  height: 100%;

  .activeFont {
    pointer-events:none;
    span{
      color: #13A3F0;
      font-weight: 600;
    }
  }
  .top {
    background: #ffffff;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: 80%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        width: 100px;
        height: 60px;
        flex-grow: 0;
        cursor: pointer;

        img {
          width: 100%;
          height: 46px;
          margin-top: 7px;
        }
      }

      .right {
        width: 30%;
        height: 100%;
      }
      .main-nav{
        height: 100%;
      }
      .right .a{
        display:inline-block;
        text-decoration:none;
        color: #000;;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-size: 15px;
        font-weight:600;
        margin:0 40px;
        height: 40px;
        line-height: 60px;
        overflow: hidden;
      }
      .right .a span{
        cursor: pointer;
      display: inline-block;
      position:relative;
      transition: transform 500ms;
      -webkit-transition: -webkit-transform 500ms;
      -moz-transition: transform 500ms;
     }
     .right .a span:after{
        content: attr(data-hover);
        position: absolute;
        top: -40px;
        left:0;
        transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
      }
      .right .a:hover span,
      .right .a:focus span {
        transform: translateY(40px);
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
      }
    }
  }
}
</style>
